import React, { useState } from 'react';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Chat from './components/Chat/Chat';
import LogoHeader from './components/LogoHeader/LogoHeader';
import CoralsFooter from './components/CoralsFooter/CoralsFooter'
import './App.css';
import ToogleTheme from './components/ToggleTheme/ToogleTheme';

const App = () => {
  const [darkTheme, setDarkTheme] = useState(false);

  const toggleTheme = () => {
    setDarkTheme(!darkTheme);
    if (!darkTheme) {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }
  };

  return (
    <div className={`app-container ${darkTheme ? 'dark-theme' : ''}`}>
      <ToogleTheme darkTheme={darkTheme} toggleTheme={toggleTheme} />
      <LogoHeader darkTheme={darkTheme}/>
      <Chat darkTheme={darkTheme} />
      <CoralsFooter darkTheme={darkTheme}/> 
    </div>
  );
};

export default App;
